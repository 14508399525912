









































































































import {Component, Prop, Vue} from 'vue-property-decorator';
import QueryDataTable from '@/layouts/back-office/elements/datatable/QueryDataTable.vue';
import {DataTableColumn} from '@/layouts/back-office/elements/datatable/DataTableMixin';
import {Branch_branch, Dealer_dealer, PaginateDealerAccounts_paginateDealerAccounts_data} from '@/types/intrador';
import EditAccountAction from '@/components/account/actions/EditAccountAction.vue';
import DetachDealerAccountAction from '@/components/dealer/actions/DetachDealerAccountAction.vue';

@Component({
  components: {
    DetachDealerAccountAction,
    EditAccountAction,
    QueryDataTable,
  },
})
export default class AccountTab extends Vue {
  @Prop(Object) protected dealer!: Dealer_dealer;
  @Prop(Object) protected branch?: Branch_branch;

  private refetchQuery: any = null;
  private editableItem: any = null;

  public refetch() {
    (this.$refs.queryDataTable as QueryDataTable).refresh();
  }

  get variables() {
    const variables: any = {limit: 50, dealerId: this.$route.params.id};

    if (this.branch) {
      variables.branchId = this.branch.id;
    }

    return variables;
  }

  /**
   * The DataTable columns
   */
  get columns(): DataTableColumn[] {
    return [
      {
        title: '#',
        key: 'id',
        sortable: true,
        class: 'data-table-col-fixed',
        width: 65,
      },

      {
        title: this.$it('accounts.col.name.title', 'Name').toString(),
        key: 'name',
        sortable: true,
        class: 'data-table-col-middle',
        width: 250,
      },

      {
        title: this.$it('accounts.col.email.title', 'Email').toString(),
        key: 'email',
        sortable: true,
        class: 'data-table-col-middle',
        width: 250,
      },

      {
        title: this.$it('accounts.col.phone.title', 'Phone').toString(),
        key: 'phone',
        sortable: true,
        class: 'data-table-col-middle',
        width: 100,
      },
      {
        title: this.$it('accounts.col.locations.title', 'Locations').toString(),
        key: 'addresses',
        sortable: true,
        class: 'data-table-col',
        width: 200,
      },
      {
        title: this.$it('accounts.col.language.title', 'Language').toString(),
        key: 'language',
        sortable: true,
        class: 'data-table-col-middle data-table-col-fixed',
        width: 120,
      },
      {
        title: this.$it('accounts.col.created.title', 'Created').toString(),
        key: 'hasPassword',
        sortable: true,
        class: 'data-table-col-middle data-table-col-fixed',
        width: 100,
      },
      {
        title: this.$it('accounts.col.inspection-open.title', 'Insp. Open').toString(),
        key: 'inspectionsAmountTotal',
        class: 'data-table-col-middle data-table-col-fixed',
        width: 100,
      },
      {
        title: this.$it('accounts.col.app-version.title', 'App version').toString(),
        key: 'device.appVersion',
        class: 'data-table-col-middle data-table-col-fixed',
        slot: 'appVersion',
        width: 120,
      },
      {
        key: 'edit',
        permission: 'dealers-accounts-edit',
        class: 'data-table-col-fixed data-table-col-middle',
        width: 100,
        select: false,
      },
    ];
  }

  private refetchQueryUpdated(refetchQuery: any) {
    this.refetchQuery = refetchQuery;
  }

  private mounted() {
    this.$nextTick(() => {
      this.refetch();
    });
  }

  private getAddressesOfCurrentBranch(addresses: any[]) {
    return addresses.filter((address: any) => address.entity && address.entity.id === this.dealer.id);
  }

  private getAppVersionMessage(item: any) {
    if (item.device === null) {
      return this.$it('accounts.device.error.no-app-installed', 'This user has no app installed');
    } else if (item.device.outdated) {
      return this.$it('accounts.device.error.app-outdated', 'The app of the user is outdated');
    } else {
      return this.$it('accounts.device.up-to-date', 'The app is up to date');
    }
  }

  private editAccount(item: any) {
    this.editableItem = item;
    this.$nextTick(() => {
      (this.$refs.editAccountAction as EditAccountAction).open();
    });
  }

  private detachAccount(item: PaginateDealerAccounts_paginateDealerAccounts_data) {
    this.editableItem = item;
    this.$nextTick(() => {
      (this.$refs.detachDealerAccountAction as DetachDealerAccountAction).open();
    });
  }
}
