

















































































































































































import {Component, Vue} from 'vue-property-decorator';
import {Filter, FilterType} from '@/layouts/back-office/elements/filters/FilterMixin';
import {Location} from 'vue-router';
import AccountsTab from '@/components/dealer/tabs/AccountsTab.vue';
import ActionBar from '@/layouts/back-office/elements/ActionBar.vue';
import ActionButton from '@/layouts/back-office/elements/actions/ActionButton.vue';
import {ActionMenuDirection} from '@/layouts/back-office/elements/ActionMenu.vue';
import BranchesMap from '@/components/dealer/cards/BranchesMap.vue';
import BranchesTab from '@/components/dealer/tabs/BranchesTab.vue';
import AuditsTab from '@/components/dealer/tabs/AuditsTab.vue';
import CardChartBranchStatistics from '@/components/dealer/cards/charts/CardChartBranchStatistics.vue';
import CreateAccountAction from '@/components/account/actions/CreateAccountAction.vue';
import CreateBranchAction from '@/components/dealer/actions/CreateBranchAction.vue';
import Detail from '@/layouts/back-office/main/Detail.vue';
import FilterButton from '@/layouts/back-office/elements/filters/FilterButton.vue';
import MetaList from '@/components/MetaList.vue';
import MonthlyEquipmentSituation from '@/components/dashboard/cards/charts/MonthlyEquipmentSituation.vue';
import MonthlyStatus from '@/components/dashboard/cards/charts/MonthlyStatus.vue';
import SnapshotsTab from '@/components/dealer/tabs/SnapshotsTab.vue';
import TabView, {Tab} from '@/layouts/back-office/elements/TabView.vue';
import AttachDealerAccountAction from '@/components/dealer/actions/AttachDealerAccountAction.vue';
import TimesInDemonstrationExport from '@/components/inspection/actions/TimesInDemonstrationExport.vue';
import TimesInRentalExport from '@/components/inspection/actions/TimesInRentalExport.vue';
import {EntityType} from '@/types/auction';
import CountNotContentAudits from '@/components/dealer/cards/CountNotContentAudits.vue';
import CountHalfContentAudits from '@/components/dealer/cards/CountHalfContentAudits.vue';
import CountContentAudits from '@/components/dealer/cards/CountContentAudits.vue';
import CountAuditsPlannedAndDone from '@/components/dealer/cards/CountAuditsPlannedAndDone.vue';
import ExportDealerInspections from '@/components/dealer/actions/ExportDealerInspections.vue';
import ExportDealerAuditPdf from '@/components/dealer/actions/ExportDealerAuditPdf.vue';
import {pastDays} from '@/plugins/Date';

@Component({
    components: {
      ExportDealerAuditPdf,
      ExportDealerInspections,
      Detail,
      MetaList,
      ActionBar,
      FilterButton,
      ActionButton,
      CreateAccountAction,
      AttachDealerAccountAction,
      CreateBranchAction,
      TimesInDemonstrationExport,
      TimesInRentalExport,
      CountAuditsPlannedAndDone,
      CountContentAudits,
      CountHalfContentAudits,
      CountNotContentAudits,
      BranchesMap,
      MonthlyEquipmentSituation,
      MonthlyStatus,
      CardChartBranchStatistics,
      TabView,
      BranchesTab,
      AuditsTab,
      SnapshotsTab,
      AccountsTab,
    },
  })
  export default class DealerDetail extends Vue {
    private direction: ActionMenuDirection = ActionMenuDirection.BottomRight;
    private date: DateRange | null = null;

    private EntityType = EntityType;

    get tabs(): Tab[] {
      return [
        {slot: 'locations', title: this.$it('dealer.locations.title', 'Locations') as string, guttered: false},
        {slot: 'audits', title: this.$it('dealer.audits.title', 'Audits') as string, guttered: false},
        {slot: 'inspections', title: this.$it('dealer.inspections.title', 'Inspections') as string, guttered: false},
        {slot: 'accounts', title: this.$it('dealer.accounts.title', 'Accounts') as string, guttered: false},
      ];
    }

    get backFallback(): Location {
      if (this.$route.name === 'branches-detail') {
        return {name: 'dealers-detail', params: {id: this.$route.params.id}};
      }

      return {name: 'locations'};
    }

    get query() {
      if ('branchId' in this.$route.params) {
        return require('@/graphql/queries/dealers/DealerAddress.gql');
      }

      return require('@/graphql/queries/dealers/Dealer.gql');
    }

    get variables() {
      const variables: any = {id: this.$route.params.id};
      if ('branchId' in this.$route.params) {
        variables.addressId = this.$route.params.branchId;
      }
      if (this.date) {
        variables.date = {from: this.date.start, to: this.date.end};
      }

      return variables;
    }

    get startDate(): Date {
      if (this.date && this.date.end) {
        return this.date.end;
      }
      return new Date();
    }

  get endDate(): Date {
    if (this.date && this.date.start) {
      return this.date.start;
    }
    const date = new Date();
    date.setMonth(date.getMonth() - 12);
    return date;
  }

    /**
     * The DataTable filters
     */
    get dateFilter(): Filter {
      return {
        key: 'date',
        title: this.$it('dealers.filter.date.title', 'Date') as string,
        type: FilterType.Date,
        options: {
          fixedDates: [
            {
              label: this.$it('global.date.last-24-hours', 'Last 24 hours') as string,
              start: pastDays(1),
              end: new Date(),
            },
            {
              label: this.$it('global.date.last-7-days', 'Last 7 days') as string,
              start: pastDays(7),
              end: new Date(),
            },
            {
              label: this.$it('global.date.last-14-days', 'Last 14 days') as string,
              start: pastDays(14),
              end: new Date(),
            },
            {
              label: this.$it('global.date.last-30-days', 'Last 30 days') as string,
              start: pastDays(30),
              end: new Date(),
            },
            {label: this.$it('global.date.all-time', 'All time') as string},
          ],
        },
      };
    }

    private refreshData() {
      if (this.$refs.tabBranches as BranchesTab) {
        (this.$refs.tabBranches as BranchesTab).refetch();
      }
      if (this.$refs.tabAccounts as AccountsTab) {
        (this.$refs.tabAccounts as AccountsTab).refetch();
      }
    }
  }
