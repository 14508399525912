











import {Component, Mixins, Prop} from 'vue-property-decorator';
import {shortMonthTranslation} from '@/filters/vue/dateTime';
import BarChart from '@/layouts/back-office/elements/charts/BarChart.vue';
import BarChartMixin from '@/layouts/back-office/elements/charts/BarChartMixin';
import {StatisticsGroup} from '@/types';
import MonthChartMixin from '@/layouts/back-office/elements/charts/MonthChartMixin';

@Component({
  components: {BarChart},
})
export default class MonthBarChart extends Mixins(BarChartMixin, MonthChartMixin) {
  get monthsData(): StatisticsGroup[] {
    const currentDate = new Date(Date.UTC(this.startDate.getFullYear(), this.startDate.getMonth(), 2, 12, 0, 0));
    const endDate = new Date(Date.UTC(this.endDate.getFullYear(), this.endDate.getMonth(), 2, 12, 0, 0));

    const data: StatisticsGroup[]  = [];

    while (
      ((this.startDate < this.endDate && currentDate <= endDate)
        || (this.startDate >= this.endDate && currentDate >= endDate))
      && data.length < this.numberOfMonths
  ) {
      const year = currentDate.getFullYear();
      const month = currentDate.getMonth() + 1;
      const index = `${year}-${month}`;

      const dataFromMonth =
        this.statisticsGroups.find((group: StatisticsGroup) => group.name.toString() === index.toString());

      data.push({
        id: index.toString(),
        name: shortMonthTranslation(month) + ' ' + year,
        partitions: (dataFromMonth) ? dataFromMonth.partitions : [],
      });

      if (this.startDate < this.endDate) {
        currentDate.setMonth(currentDate.getMonth() + 1);
      } else {
        currentDate.setMonth(currentDate.getMonth() - 1);
      }
    }

    return data;
  }
}
