
















































































import {Component, Prop, Vue} from 'vue-property-decorator';
import QueryDataTable from '@/layouts/back-office/elements/datatable/QueryDataTable.vue';
import {Filter} from '@/layouts/back-office/elements/filters/FilterMixin';
import {DataTableColumn, DataTableSearchable} from '@/layouts/back-office/elements/datatable/DataTableMixin';
import EquipmentSituationIcon from '@/components/snapshot/EquipmentSituationIcon.vue';
import InspectionStatusLabel from '@/components/inspection/InspectionStatusLabel.vue';
import {Branch_branch, Dealer_dealer, InspectionStatus} from '@/types/intrador';

@Component({
  components: {InspectionStatusLabel, EquipmentSituationIcon, QueryDataTable},
})
export default class SnapshotsTab extends Vue {
  @Prop(Object) protected dealer!: Dealer_dealer;
  @Prop(Object) protected branch?: Branch_branch;
  @Prop(Object) protected date!: DateRange | null;

  private statusExpired = InspectionStatus.EXPIRED;

  get variables() {
    const variables: any = {limit: 50, dealerId: this.$route.params.id};

    if (this.branch) {
      variables.branchId = this.branch.id;
    }

    if (this.date) {
      variables.date = {
        start: this.date.start,
        end: this.date.end,
      };
    }

    return variables;
  }

  /**
   * The DataTable columns
   */
  get columns(): DataTableColumn[] {
    return [
      {
        title: '',
        key: 'id',
        sortable: false,
        class: 'data-table-col-fixed',
        width: 70,
      },
      {
        title: this.$it('snapshot.col.asset.title', 'Asset').toString(),
        key: 'asset.name',
        sortable: true,
        width: 500,
      },
      {
        title: this.$it('snapshot.col.branch.title', 'Branch').toString(),
        key: 'address',
        sortable: 'address.name',
        class: 'data-table-col-middle',
        width: 200,
      },
      {
        title: this.$it('snapshot.col.inspector.title', 'Inspector').toString(),
        key: 'inspector',
        sortable: 'inspector.name',
        class: 'data-table-col-fixed',
        width: 200,
      },
      {
        title: this.$it('snapshot.col.planning.title', 'Planning').toString(),
        key: 'plannedAt',
        sortable: true,
        class: 'data-table-col-fixed',
        width: 120,
      },
      {
        title: this.$it('snapshot.col.equipmentSituation.title', 'Situation').toString(),
        key: 'equipmentSituation',
        sortable: true,
        class: 'data-table-col-fixed data-table-col-center',
        width: 120,
      },
      {
        title: this.$it('snapshot.col.anomaly.title', 'Anomalies').toString(),
        key: 'warningsAmount',
        class: 'data-table-col-fixed data-table-col-center',
        width: 100,
        permission: 'inspections-warnings-get',
      },
      {
        title: this.$it('snapshot.col.status.title', 'Status').toString(),
        key: 'status',
        sortable: true,
        class: 'data-table-col-fixed data-table-col-center',
        width: 120,
      },
    ];
  }
}
