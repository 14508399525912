










































import {Component, Prop, Vue, Watch} from 'vue-property-decorator';
import {
  BranchInput,
  PaginateBranches_paginateBranches_data,
} from '@/types/intrador';
import Modal from '@/layouts/back-office/elements/modals/Modal.vue';
import InputAddress, {Address} from '@/layouts/back-office/elements/input/InputAddress.vue';
import InputGeolocation from '@/layouts/back-office/elements/input/InputGeolocation.vue';
import SpinnerButton from '@/layouts/back-office/elements/SpinnerButton.vue';
import InputText from '@/layouts/back-office/elements/input/InputText.vue';
import InputDate from '@/layouts/back-office/elements/input/InputDate.vue';
import InputInspector from '@/components/elements/input/InputInspector.vue';

@Component({
  components: {InputDate, InputText, InputInspector, SpinnerButton, InputGeolocation, InputAddress, Modal},
})
export default class EditBranchAction extends Vue {
  @Prop(Object) protected branch!: PaginateBranches_paginateBranches_data;
  @Prop({type: Boolean, default: true}) protected frequencyAndDate!: boolean;

  private address: Address | null = null;
  private savable: boolean = false;

  get addressInput(): BranchInput {
    return {
      ...this.$inspection.resolveAddressInput(this.address),
    };
  }

  public open() {
    this.$nextTick(() => {
      const modal = this.$refs.editBranchAction as Modal;
      modal.open();
    });
  }

  public close() {
    const modal = this.$refs.editBranchAction as Modal;
    modal.close();
  }

  private save(mutate: () => void) {
    const inputAddressRef = this.$refs.inputAddress as InputAddress;

    if (inputAddressRef && inputAddressRef.check()) {
      mutate();
    }
  }

  private onUpdate() {
    const inputAddressRef = this.$refs.inputAddress as InputAddress;

    if (inputAddressRef) {
      this.savable = inputAddressRef.silentCheck();
    }
  }

  @Watch('branch', {immediate: true})
  private branchUpdated() {
    this.address = (this.branch) ? Object.assign({ dealer: undefined }, this.branch) : null;
  }

  private done() {
    this.$emit('done', {address: this.addressInput});
    this.close();
  }
}
