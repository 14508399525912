import {Component, Prop, Vue} from 'vue-property-decorator';

@Component
export default class MonthChartMixin extends Vue {
  @Prop({
    type: Number, default: 12, validator(value: any): boolean {
      return value <= 12;
    },
  }) protected numberOfMonths!: number;
  @Prop({type: Date, default: new Date()}) protected startDate!: Date;
  @Prop({
    type: Date,
    default: () => {
      const date = new Date();
      date.setMonth(date.getMonth() - 12);
      return date;
    },
  }) protected endDate!: Date;
}
