import { render, staticRenderFns } from "./CountContentAudits.vue?vue&type=template&id=845daf32&scoped=true&"
import script from "./CountContentAudits.vue?vue&type=script&lang=ts&"
export * from "./CountContentAudits.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "845daf32",
  null
  
)

export default component.exports