

































import {Component, InjectReactive, Prop, Vue, Watch} from 'vue-property-decorator';
import Spinner from '@/layouts/back-office/elements/Spinner.vue';
import {Location} from 'vue-router';

@Component({
  components: {Spinner},
})
export default class BackPress extends Vue {
  @Prop({type: Boolean, default: false}) protected loading!: boolean;
  @Prop({type: Object, required: false}) protected backFallback!: Location;
  @Prop({type: Object, required: false}) protected link?: Location;
  @Prop({type: Boolean, default: false}) protected sticky!: boolean;

  private scrolled: boolean = false;
  private hasLoadedOnes: boolean = false;

  private created() {
    if (this.sticky) {
      window.addEventListener('scroll', this.scroll.bind(this));
    }
  }

  private beforeDestroy() {
    if (this.sticky) {
      window.removeEventListener('scroll', this.scroll.bind(this));
    }
  }

  private scroll() {
    this.scrolled = window.scrollY > 0;
  }


  @Watch('loading', {immediate: true})
  private loadingChange(value: boolean) {
    if (!value) {
      this.hasLoadedOnes = true;
    }
  }

  private back() {
    if (this.link) {
      this.$router.push(this.link);
    } else if (this.$route.meta.previous) {
      this.$router.back();
    } else {
      this.$router.push(this.backFallback);
    }
  }
}
