














import { Component, Mixins, Prop } from 'vue-property-decorator';
import { PaginateInspections_paginateInspections_data } from '@/types/intrador';
import ActionMixin from '@/layouts/back-office/elements/actions/ActionMixin';
import DownloadActionModal from '@/layouts/back-office/elements/actions/DownloadActionModal.vue';
import Toggle, { ToggleChoice } from '@/components/elements/button/Toggle.vue';

@Component({
  components: {Toggle, DownloadActionModal},
})
export default class TimesInDemonstrationExport extends Mixins(ActionMixin) {
  public title: string =
    this.$it('inspection.times-in-demonstration.export.title', 'In demonstration export') as string;
  public icon?: string = 'upload';
  public permission: string = 'inspections-export';

  @Prop({type: String, default: 'times-in-demonstration.xlsx'}) protected filename!: string;

  @Prop(String) protected dealerId?: string;
  @Prop(String) protected addressId?: string;

  get variables() {
    return {
      'dealer-id': this.dealerId,
    };
  }

  protected onSelect() {
    (this.$refs.actionModal as DownloadActionModal).open();
  }

}
