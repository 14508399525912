


























































































































import {Component, Emit, Model, Prop, Vue, Watch} from 'vue-property-decorator';
import InputText from '@/layouts/back-office/elements/input/InputText.vue';
import LanguageSelect from '@/layouts/back-office/elements/input/InputLanguage.vue';
import {Account_account_entity_availableRoles} from '@/types/intrador';
import {AddressList, EntityType} from '@/types/auction';
import InputTabSelect from '@/layouts/back-office/elements/input/InputTabSelect.vue';
import {InputSelectOptions} from '@/layouts/back-office/elements/input/InputSelectMixin';
import InputSelect from '@/layouts/back-office/elements/input/InputSelect.vue';
import InputEntity, {EntitySelectResponse} from '@/components/entity/input/InputEntity.vue';

export interface UserType {
  key: string;

  entityType: EntityType;
  label: string;

  roles?: string[];
}

export interface UserFormInput {
  company?: EntitySelectResponse|null;
  address?: string|null;

  email: string|null;

  displayName: string|null;

  firstName: string|null;
  lastName: string|null;
  phone: string|null;

  language: string|null;

  activeRoles: string[];
  entityTypes?: EntityType[];
}

export interface UserFormResponse extends UserFormInput {
  email: string;

  firstName: string;
  lastName: string;
}

@Component({
  components: {InputEntity, InputSelect, InputTabSelect, InputText, LanguageSelect},
})
export default class UserForm extends Vue {
  @Model('change') protected value!: UserFormResponse|null;

  @Prop(String) protected defaultUserType?: string;
  @Prop({type: Array, default: () => []}) protected availableUserTypes!: UserType[];

  @Prop(String) protected defaultAddress?: string;
  @Prop({type: Array, default: () => []}) protected addresses!: AddressList[];

  protected selectedUserTypeKey: string|null = null;

  protected user: UserFormInput = {
    company: null,
    address: null,

    email: null,

    displayName: null,
    firstName: null,
    lastName: null,

    phone: null,

    language: null,

    activeRoles: [],
    entityTypes: [],
  };

  @Watch('defaultUserType', {immediate: true})
  @Watch('availableUserTypes')
  protected updateEntityType() {
    if (this.selectedEntityType === null) {
      if (this.defaultUserType) {
        this.selectedUserTypeKey = this.defaultUserType;
      } else if (this.availableUserTypes.length === 1) {
        this.selectedUserTypeKey = this.availableUserTypes[0].entityType;
      } else if (this.availableUserTypes.length === 0) {
        this.selectedUserTypeKey = null;
      }
    }
  }

  @Watch('defaultAddress', {immediate: true})
  @Watch('addresses')
  protected updateAddress() {
    if (this.user.address === null) {
      if (this.defaultAddress) {
        this.user.address = this.defaultAddress;
      } else if (this.addresses.length === 1) {
        this.user.address = this.addresses[0].id;
      } else if (this.addresses.length === 0) {
        this.user.address = null;
      }
    }
  }

  protected get selectedUserType(): UserType|null {
    return this.availableUserTypes.find((aut) => aut.key === this.selectedUserTypeKey) ?? null;
  }

  protected get selectedEntityType(): EntityType|null {
    return (this.selectedUserType) ? this.selectedUserType.entityType : null;
  }

  protected get isBackOfficeUser(): boolean {
    return this.selectedEntityType === EntityType.CURRENT;
  }

  protected get isDealerUser(): boolean {
    return (this.selectedEntityType)
      ? this.selectedEntityType === EntityType.DEALER
      : this.defaultUserType === EntityType.DEALER;
  }

  @Watch('value', {immediate: true})
  protected updateUser(value: UserFormInput|null) {
    const activeRoles: string[] = (value)
      ? value.activeRoles.filter((roleID) => this.roles.find((role) => role.id === roleID))
      : [];

    this.user = {
      company: value?.company ?? null,
      address: value?.address ?? null,

      email: value?.email ?? null,

      displayName: value?.displayName ?? null,
      firstName: value?.firstName ?? null,
      lastName: value?.lastName ?? null,

      phone: value?.phone ?? null,

      language: value?.language ?? null,

      activeRoles: activeRoles ?? [],
      entityTypes: value?.entityTypes ?? [],
    };

    this.updateEntityType();
    this.updateAddress();
  }

  @Emit('change')
  protected updateModel(): UserFormResponse | null {
    if (this.user.email === null
      || this.user.firstName === null || this.user.lastName === null) {
      return null;
    }

    return {
      company: this.user.company,
      address: this.user.address,
      email: this.user.email,
      displayName: this.user.displayName,
      firstName: this.user.firstName,
      lastName: this.user.lastName,
      phone: this.user.phone,
      language: this.user.language,
      activeRoles: this.user.activeRoles,
      entityTypes: (this.selectedEntityType) ? [this.selectedEntityType] : [],
    };
  }

  @Watch('selectedUserType', {immediate: true})
  protected userTypeUpdated(userType: UserType|null) {
    if (userType) {
      this.user.activeRoles = [];
      if (userType && userType.roles) {
        this.setActiveRoles(userType.roles);
      }
    }
  }

  protected get roles(): Account_account_entity_availableRoles[] {
    return this.$store.getters.user.entity.availableRoles;
  }

  protected setActiveRoles(roleIDs: string[]) {
    roleIDs.forEach((roleID) => this.setActiveRole(roleID));
  }

  protected setActiveRole(roleID: string) {
    if (!this.roles.find((role) => role.id === roleID)) {
      return;
    }

    if (!this.user.activeRoles.includes(roleID)) {
      this.user.activeRoles.push(roleID);
    }
  }

  protected toggleRoles(roleID: string) {
    if (this.user.activeRoles.includes(roleID)) {
      this.removeItemOnce(this.user.activeRoles, roleID);
    } else {
      this.setActiveRole(roleID);
    }

    this.updateModel();
  }

  protected removeItemOnce(arr: any[], value: any) {
    const index = arr.indexOf(value);
    if (index > -1) {
      arr.splice(index, 1);
    }
    return arr;
  }
}
