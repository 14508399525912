



















import {Component, Prop, Vue} from 'vue-property-decorator';
import ActionModal from '@/layouts/back-office/elements/actions/ActionModal.vue';
import {PaginateDealerAccounts_paginateDealerAccounts_data} from '@/types/intrador';
import InputSelect from '@/layouts/back-office/elements/input/InputSelect.vue';
import {InputSelectOptions} from '@/layouts/back-office/elements/input/InputSelectMixin';

@Component({
  components: {ActionModal, InputSelect},
})
export default class DetachDealerUserAction extends Vue {
  @Prop(Object) protected account!: PaginateDealerAccounts_paginateDealerAccounts_data;
  @Prop(String) private dealerId!: string;
  @Prop({type: Object, required: false}) private branch?: any;
  @Prop(Object) private paginateDealerAccountsQuery?: any;

  private branchIds: string[] = [];

  get variables() {
    return {
      dealerId: this.dealerId,
      branchIds: this.branchIds,
      userId: (this.account) ? this.account?.id : null,
    };
  }

  get branchesOptions(): InputSelectOptions {
    const options: InputSelectOptions = {};
    const allBranchIds: string[] = [];

    this.account.addresses!.forEach((address: any) => {
      allBranchIds.push(address.id);
      options[`${this.$it('detach.branch.all-locations.option', 'All locations')}`] = allBranchIds;
      options[`${address.name} - ${address.line}`] = address.id;
    });

    return options;
  }

  get refetchQueries() {
    if (!this.paginateDealerAccountsQuery) {
      return [];
    }

    return [this.paginateDealerAccountsQuery];
  }

  get items() {
    if (this.account) {
      return [this.account];
    }

    return [];
  }

  public open() {
    const modal = this.$refs.actionModal as ActionModal;
    modal.open();
  }

  public close() {
    const modal = this.$refs.actionModal as ActionModal;
    modal.close();
  }

}
