





















import {Component, Prop, Vue} from 'vue-property-decorator';
import InputSelect from '@/layouts/back-office/elements/input/InputSelect.vue';
import ActionsRegistrarMixin, {ActionItem} from '@/layouts/back-office/elements/actions/ActionsRegistrarMixin';
import {
  PaginateAudits,
  PaginateAudits_paginateAudits_data,
  PaginateAudits_paginateAudits_data_inspections,
} from '@/types/intrador';
import {InputSelectOptions} from '@/layouts/back-office/elements/input/InputSelectMixin';
import slugify from '@/filters/vue/slugify';
import DownloadAuditReportAction from '@/components/audits/actions/DownloadAuditReportAction.vue';

@Component({
  components: {
    DownloadAuditReportAction,
    InputSelect,
  },
})
export default class ExportDealerAuditPdf extends Vue {
  @Prop({type: Function, required: false}) protected register!: (item: ActionItem) => ActionsRegistrarMixin;
  @Prop(String) protected entityId!: string;
  @Prop(String) protected addressId?: string;

  protected data: PaginateAudits | null = null;
  protected audit: PaginateAudits_paginateAudits_data | null = null;

  get variables() {
    const variables: any = {dealerIds: [this.entityId]};
    if (this.addressId) {
      variables.branchIds = [this.addressId];
    }
    return variables;
  }

  get inspections(): PaginateAudits_paginateAudits_data_inspections[] {
    return this.audit?.inspections ?? [];
  }

  get filename(): string | null {
    if (this.audit) {
      if (this.audit.import) {
        const filename = this.audit.import.filename;

        if (filename.substr(-4) === '.xls') {
          return filename.substr(0, filename.length - 4);
        } else if (filename.substr(-5) === '.xlsx') {
          return filename.substr(0, filename.length - 5);
        }

        return filename;
      } else {
        const plannedAt = this.audit.plannedAt ? this.$id(this.audit.plannedAt, 'date') : '';
        const dueAt = this.audit.dueAt ? this.$id(this.audit.dueAt, 'date') : '';
        const name = this.audit.branch ? this.audit.branch.name : '';
        return slugify(`${name}-${plannedAt}-${dueAt}`);
      }
    }

    return null;
  }

  get pdfFilename(): string | null {
    return (this.filename)
      ? this.filename + '.pdf'
      : null;
  }

  get auditOptions() {
    if (!this.data) {
      return [];
    }

    const audits = this.data.paginateAudits?.data ?? [];

    // @ts-ignore
    return audits.reduce((total: InputSelectOptions, audit: PaginateAudits_paginateAudits_data) => {
      let key = this.$id(audit.plannedAt!, 'date') + ' - ' + this.$id(audit.dueAt!, 'date');
      if (audit.import) {
        key = this.$id(audit.createdAt!, 'date') + ' - ' + audit.import.filename;
      }
      total[key] = audit;

      return total;
    }, {});
  }

  protected onData(data: PaginateAudits) {
    this.data = data;
  }
}
