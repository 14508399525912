

















import {Component, Mixins, Prop} from 'vue-property-decorator';
import { v4 as uuidv4 } from 'uuid';
import BarChartMixin, {BarChartSeries} from '@/layouts/back-office/elements/charts/BarChartMixin';
import {VueFrappe} from 'vue2-frappe';

export interface DataSet {
  name: string;
  chartType: 'bar';
  values: number[];
}

export type DataSets = DataSet[];

@Component({
  components: {
    VueFrappe,
  },
})
export default class BarChart extends Mixins(BarChartMixin) {
  @Prop({type: Boolean, default: true}) protected isStacked!: boolean;

  get id() {
    return 'chart-' + uuidv4();
  }

  get colors() {
    return this.seriesOptions.map((option) => option.color).filter((color) => color);
  }

  get labels() {
    return this.statisticsGroups.map((group) => group.name);
  }

  get dataSets() {
    return this.seriesOptions.reduce((dataSets: DataSets, option: BarChartSeries) => {
      const dataSet: DataSet = {
        name: option.label,
        chartType: 'bar',
        values: this.statisticsGroups.map((group) => {
          const partition = group.partitions.find(((p) => p.name === option.key));
          return (partition) ? partition.amount! : 0;
        }),
      };

      dataSets.push(dataSet);
      return dataSets;
    }, []);
  }

  get yMinimum() {
    return 0;
  }

  get yMaximum() {
    const values = this.statisticsGroups
      .map((group) => group.partitions.reduce((total, partition) => total + partition.amount!, 0));
    return Math.max(...values);
  }
}
