



















































































import {Component, Prop, Vue} from 'vue-property-decorator';
import {DataTableColumn} from '@/layouts/back-office/elements/datatable/DataTableMixin';
import {Branch_branch, Dealer_dealer, PaginateBranches_paginateBranches_data} from '@/types/intrador';
import DataTable from '@/layouts/back-office/elements/datatable/DataTable.vue';
import EditBranchAction from '@/components/branch/actions/EditBranchAction.vue';
import RemoveBranchAction from '@/components/branch/actions/RemoveBranchAction.vue';
import QueryDataTable from '@/layouts/back-office/elements/datatable/QueryDataTable.vue';

@Component({
  components: {QueryDataTable, EditBranchAction, RemoveBranchAction, DataTable},
})
export default class BranchesTab extends Vue {
  @Prop(Object) protected dealer!: Dealer_dealer;
  @Prop(Object) protected branch?: Branch_branch;

  private editableItem: PaginateBranches_paginateBranches_data | null = null;

  public refetch() {
    (this.$refs.queryDataTable as QueryDataTable).refresh();
  }

  get variables() {
    return {
      dealerId: this.dealer.id,
    };
  }

  /**
   * The DataTable columns
   */
  get columns(): DataTableColumn[] {
    return [
      {
        title: this.$it('global.external-id', 'External ID') as string,
        key: 'externalId',
        class: 'data-table-col-min data-table-col-middle',
        sortable: true,
        width: 100,
      },
      {
        title: this.$it('branch.col.name.title', 'Name') as string,
        key: 'name',
        sortable: true,
      },
      // {
      //   title: this.$it('branch.col.city.title', 'City').toString(),
      //   key: 'city',
      //   class: 'data-table-col-min data-table-col-middle',
      //   sortable: true,
      //   width: 150,
      // },
      {
        title: this.$it('branch.col.contact.title', 'Contact') as string,
        key: 'contact',
      },
      {
        title: this.$it('branch.col.next_audit.title', 'Next audit') as string,
        key: 'nextAudit',
        class: 'data-table-col-fixed data-table-col-middle',
        sortable: true,
        width: 150,
      },
      {
        title: this.$it('branch.col.next_trusted_audit.title', 'Next trusted  audit') as string,
        key: 'nextTrustedAudit',
        class: 'data-table-col-fixed data-table-col-middle',
        sortable: true,
        width: 150,
      },
      {
        key: 'edit',
        permission: 'dealers-branches-edit',
        class: 'data-table-col-fixed data-table-col-middle',
        width: 100,
        select: false,
      },
    ];
  }

  private mounted() {
    this.$nextTick(() => {
      this.refetch();
    });
  }

  private branchEdit(branch: PaginateBranches_paginateBranches_data) {
    this.editableItem = branch;
    (this.$refs.editBranchModal as EditBranchAction).open();
  }

  private branchRemove(branch: PaginateBranches_paginateBranches_data) {
    this.editableItem = branch;
    (this.$refs.removeBranchModal as RemoveBranchAction).open();
  }
}
