




import {Component, Prop, Vue} from 'vue-property-decorator';
import NumberCard from '@/components/dealer/cards/NumberCard.vue';
import {AuditStatus, Dealer} from '@/types/auction';

@Component({
  components: {NumberCard},
})
export default class CountInspectionsPlanned extends Vue {
  @Prop(Object) protected data!: Dealer;

  get amount() {
    return `${this.planned} / ${this.performed}`;
  }

  get planned() {
    return this.data.paginateAudits!.statistics.byStatus
      .filter((status) => status.name === AuditStatus.IN_PROGRESS)
      .reduce((total, status) => total + status.amount, 0);
  }

  get performed() {
    return this.data.paginateAudits!.statistics.byStatus
      .filter((status) => status.name === AuditStatus.IN_REVIEW || status.name === AuditStatus.CLOSED)
      .reduce((total, status) => total + status.amount, 0);
  }
}
