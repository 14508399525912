













import {Component, Prop, Vue, Watch} from 'vue-property-decorator';
import Card from '@/layouts/back-office/elements/Card.vue';
import {Route} from 'vue-router';
import {DealerAddressDetail, DealerDetail} from '@/types/auction';

@Component({
  components: {Card},
})
export default class BranchesMap extends Vue {
  @Prop(Object) protected dealer!: DealerDetail;
  @Prop(Array) protected addresses!: DealerAddressDetail[];
  @Prop(Object) protected address?: DealerAddressDetail;

  protected mapOptions = {
    mapTypeControl: false,
    streetViewControl: false,
    zoomControl: false,
    fullscreenControl: false,
    gestureHandling: 'cooperative',
  };

  protected center = {
    lat: 51.651338924464135,
    lng: 5.640076206443781,
  };

  private mounted() {
    (this.$refs.map as any).$mapPromise.then((map: any) => {
      const bounds = new (window as any).google.maps.LatLngBounds();

      if (this.addresses) {
        this.addresses.forEach((b) => {
          if (b && b.latitude && b.longitude) {
            bounds.extend({
              lat: b.latitude,
              lng: b.longitude,
            });
          }
        });
      }

      map.fitBounds(bounds);
      if (this.addresses.length === 1) {
        setTimeout(() => {
          map.setZoom(13);
        }, 100);
      }
    });
  }

  private icon(address: DealerAddressDetail) {
    if (this.address && this.address.id === address.id) {
      return '/static/map/spotlight-poi-green.png';
    }

    return '/static/map/spotlight-poi-red.png';
  }

  private select(address: DealerAddressDetail) {
    if (this.address && address.id === this.address.id) {
      const previous = (this.$route.meta.previous)
        ? this.$route.meta.previous as any as Route
        : null;

      if (previous && previous.name === 'dealers-detail') {
        this.$router.back();
      } else {
        this.$router.replace({name: 'dealers-detail', params: {id: this.dealer.id}});
      }
    } else {
      if (this.$route.name === 'dealers-detail') {
        this.$router.push({name: 'branches-detail', params: {id: this.dealer.id, branchId: address.id}});
      } else {
        this.$router.replace({name: 'branches-detail', params: {id: this.dealer.id, branchId: address.id}});
      }
    }
  }
}
