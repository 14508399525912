















import { Component, Mixins, Prop } from 'vue-property-decorator';
import DownloadActionModal from '@/layouts/back-office/elements/actions/DownloadActionModal.vue';
import ActionMixin from '@/layouts/back-office/elements/actions/ActionMixin';

@Component({ components: { DownloadActionModal } })
export default class DownloadAuditReportAction extends Mixins(ActionMixin) {
  public title: string = this.$it('dealer.action.audit-report.title', 'Audit report') as string;
  public icon?: string = 'file-pdf';

  @Prop({type: Object, default: null}) protected audit!: any | null;
  @Prop({type: String, default: 'audit.pdf'}) protected filename!: boolean;

  get url() {
    return `/inspection/audits/${this.auditId}/report`;
  }

  get auditId() {
    return this.audit?.id;
  }

  protected onSelect() {
    const modal: DownloadActionModal = this.$refs.actionModal as DownloadActionModal;

    modal.open();
  }
}
