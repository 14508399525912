var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('apollo-query',{attrs:{"query":require('@/graphql/Languages.gql'),"tag":null},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var ref_result = ref.result;
var loading = ref_result.loading;
var error = ref_result.error;
var data = ref_result.data;
var query = ref.query;
var isLoading = ref.isLoading;
return [(data)?_c('input-select',{attrs:{"clearable":_vm.clearable,"options":_vm.getOptions(data),"label":_vm.labelText,"error":error},model:{value:(_vm.value),callback:function ($$v) {_vm.value=$$v},expression:"value"}}):_c('spinner')]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }