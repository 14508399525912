









import {Component, Prop, Vue} from 'vue-property-decorator';
import Card from '@/layouts/back-office/elements/Card.vue';
import BarChart from '@/layouts/back-office/elements/charts/BarChart.vue';
import {AuditRating, Dealer} from '@/types/auction';

@Component({
  components: {BarChart, Card},
})
export default class CardChartBranchStatistics extends Vue {
  @Prop(Object) protected data!: Dealer;

  get statistics() {
    return this.data.paginateAudits!.statistics.ratingByAddress.map((statistics) => {
      const address = this.data.entity.dealerAddresses.find((a) => a.id === statistics.name);
      statistics.name = address?.name ?? statistics.name;

      return statistics;
    }) ?? [];
  }

  get hasStatistics() {
    return this.statistics.length > 0;
  }

  get options() {
    return [
      {
        color: '#8ABF59',
        label: this.$it('dealer.audits.content.title', 'Satisfactory') as string,
        key: AuditRating.CONTENT,
      },
      {
        color: '#F4B850',
        label: this.$it('dealer.audits.half-content.title', 'With reservations') as string,
        key: AuditRating.HALF_CONTENT,
      },
      {
        color: '#D74655',
        label: this.$it('dealer.audits.not-content.title', 'Unsatisfactory') as string,
        key: AuditRating.NOT_CONTENT,
      },
    ];
  }
}
