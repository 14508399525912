var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('query-data-table',{ref:"queryDataTable",attrs:{"dataKey":"paginateAudits","title":_vm.$it('audit.list.title', 'Audits'),"query":require('@/graphql/PaginateAudits.gql'),"variables":_vm.variables,"row-height":63,"columns":_vm.columns,"header":false,"bordered":false,"can-clear-filters":true},scopedSlots:_vm._u([{key:"branch.name",fn:function(ref){
var value = ref.value;
var column = ref.column;
var item = ref.item;
return [_c('div',{staticClass:"row-title"},[_c('router-link',{staticClass:"branch-name",attrs:{"to":{ name: 'audits-detail', params: { auditId: item.id }, query: { return: 'audits' } }}},[_vm._v(" "+_vm._s(value)+" ")])],1),_c('div',{staticClass:"row-subtitle"},[_c('meta-list',{attrs:{"balloon":"right","meta":item.branch.meta,"prepend":[{value: item.branch.externalId, tooltip: _vm.$it('global.location-id', 'Location Id')}],"keys":[item.branch.externalId || null, 'cnpj'],"tooltips":[_vm.$it('global.location-id', 'Location Id'), _vm.$it('global.cnpj', 'CNPJ')]}}),(item.isOnHold)?_c('on-hold-label'):_vm._e()],1)]}},{key:"plannedAt",fn:function(ref){
var value = ref.value;
var column = ref.column;
var item = ref.item;
return [(item.plannedAt && item.dueAt)?_c('div',{attrs:{"aria-label":((_vm.$id(item.plannedAt, 'time')) + " - " + (_vm.$id(item.dueAt, 'time'))),"data-balloon-pos":"left"}},[_c('div',{staticClass:"row-line"},[_vm._v(_vm._s(_vm.$id(item.plannedAt, 'date')))]),_c('div',{staticClass:"row-line"},[_vm._v(_vm._s(_vm.$id(item.dueAt, 'date')))])]):(item.plannedAt)?_c('div',{attrs:{"aria-label":("" + (_vm.$id(item.plannedAt, 'time'))),"data-balloon-pos":"left"}},[_c('div',{staticClass:"row-line"},[_vm._v(_vm._s(_vm.$id(item.plannedAt, 'date')))])]):_c('div',{attrs:{"aria-label":("" + (_vm.$id(item.createdAt, 'time'))),"data-balloon-pos":"left"}},[_c('div',{staticClass:"row-line"},[_vm._v(_vm._s(_vm.$id(item.createdAt, 'date')))])])]}},{key:"inspectionAmount",fn:function(ref){
var value = ref.value;
var column = ref.column;
var item = ref.item;
return [_c('router-link',{staticClass:"asset-number",attrs:{"aria-label":_vm.$it('view.assets.title', 'View Assets'),"data-balloon-pos":"left","tag":"button","to":{ name: 'audits-detail', params: { auditId: item.id }, query: { return: 'audits' } }}},[_vm._v(" "+_vm._s(value)+" ")])]}},{key:"number",fn:function(ref){
var value = ref.value;
var column = ref.column;
var item = ref.item;
return [_c('div',{staticClass:"amount",class:{ highlight: value > 0 }},[_vm._v(" "+_vm._s(value)+" ")])]}},{key:"equipmentSituation",fn:function(ref){
var value = ref.value;
var column = ref.column;
var item = ref.item;
return [_c('div',{staticClass:"amount",class:{ highlight: _vm.inspectionAmount(item, column) > 0 }},[_vm._v(" "+_vm._s(_vm.inspectionAmount(item, column))+" ")])]}},{key:"score",fn:function(ref){
var value = ref.value;
var column = ref.column;
var item = ref.item;
return [_c('div',{staticClass:"score"},[_c('score',{attrs:{"score":value,"balloon-pos":"left"}})],1)]}},{key:"anomalies",fn:function(ref){
var value = ref.value;
var column = ref.column;
var item = ref.item;
return [_c('anomalies-amount',{attrs:{"status":item.status,"anomalies-amount":value,"check-is-done-checking":value < 1}})]}},{key:"status",fn:function(ref){
var value = ref.value;
var column = ref.column;
var item = ref.item;
return [(value)?_c('audit-status-label',{staticClass:"status-label",attrs:{"status":value}}):_vm._e(),(item && item.rating)?_c('div',[_c('last-audit-rating',{attrs:{"audit":item}})],1):_vm._e()]}},{key:"trusted",fn:function(ref){
var value = ref.value;
var column = ref.column;
var item = ref.item;
return [(value)?_c('div',{attrs:{"data-balloon-pos":"left","aria-label":_vm.$it('audits.page.trusted.tooltip', 'This audit is being executed by a trusted party')}},[(value)?_c('i',{staticClass:"fas fa-check-circle"}):_vm._e()]):_c('div',[_c('i',{staticClass:"fa fa-minus-circle"})])]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }