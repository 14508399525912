




import {Component, Prop, Vue} from 'vue-property-decorator';
import NumberCard from '@/components/dealer/cards/NumberCard.vue';
import {AuditRating, Dealer} from '@/types/auction';

@Component({
  components: {NumberCard},
})
export default class CountInspectionsPlanned extends Vue {
  @Prop(Object) protected data!: Dealer;

  get amount() {
    return this.data.paginateAudits!.statistics.byRating
      .filter((rating) => rating.name === AuditRating.CONTENT)
      .reduce((total, rating) => total + rating.amount, 0);
  }
}
