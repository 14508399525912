
























import {Component, Mixins, Prop} from 'vue-property-decorator';
import ActionMixin from '@/layouts/back-office/elements/actions/ActionMixin';
import ActionModal from '@/layouts/back-office/elements/actions/ActionModal.vue';
import UserForm, {UserType, UserFormResponse} from '@/components/account/forms/UserForm.vue';
import {AddressList, CreateUserInput, EntityBelongsTo, EntityType} from '@/types/auction';

@Component({
  components: {UserForm, ActionModal},
})
export default class CreateAccountAction extends Mixins(ActionMixin) {
  public title = this.$it('account.create.user.title', 'Create user') as string;
  public icon: string = 'plus';
  public permission?: string | string[] = 'entity-users-create';

  @Prop(String) protected entityId?: string;

  @Prop(String) protected defaultUserType?: string;
  @Prop({type: Array, default: () => []}) protected availableUserTypes!: UserType[];

  @Prop(String) protected defaultAddress?: string;
  @Prop({type: Array, default: () => []}) protected addresses!: AddressList[];

  protected user: UserFormResponse|null = null;

  protected get variables(): CreateUserInput | null {
    if (this.user === null) {
      return null;
    }

    let entityBelongsTo: EntityBelongsTo;
    if  (this.user.company) {
      if (this.user.company.id !== null) {
        entityBelongsTo = {connect: this.user.company.id};
      } else {
        entityBelongsTo = {create: {name: this.user.company.name, types: this.user.entityTypes}};
      }
    } else {
      entityBelongsTo = {connect: (this.entityId) ? this.entityId : this.$store.getters.user.entity.id};
    }

    const userInput: CreateUserInput = {
      email: this.user.email,

      displayName: this.user.displayName,

      firstName: this.user.firstName,
      lastName: this.user.lastName,

      phone: this.user.phone,

      language: this.user.language,
      roles: this.user.activeRoles,

      entity: entityBelongsTo,
    };

    if (this.user.address) {
      userInput.addresses = {
        connect: [this.user.address],
      };
    }

    return userInput;
  }

  public open() {
    this.clearOnOpen();
    const modal: ActionModal = this.$refs.actionModal as ActionModal;

    modal.open();
  }

  protected onSelect() {
    this.open();
  }

  private clearOnOpen() {
    this.user = null;
  }
}
