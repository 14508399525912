













































import {Component, Prop, Vue} from 'vue-property-decorator';
import Spinner from '@/layouts/back-office/elements/Spinner.vue';
import BackPress from '@/layouts/back-office/main/BackPress.vue';
import {Location} from 'vue-router';
import {SmartQuery} from 'vue-apollo/types/vue-apollo';

Component.registerHooks([
  'beforeRouteEnter',
]);

@Component({
  components: {
    BackPress,
    Spinner,
  },
})
export default class Detail extends Vue {
  @Prop({type: String}) protected clientId?: string;
  @Prop({type: Object, required: true}) protected query!: object;
  @Prop({type: Object, required: true}) protected variables!: object;
  @Prop({type: String, default: 'cache-first'}) protected fetchPolicy!: string;
  @Prop({type: Function, default: null}) protected update!: (data: any) => any | null;
  @Prop({type: Object, required: false}) protected backFallback?: Location;
  @Prop({type: Object, required: false}) protected link!: Location;
  @Prop({type: String, required: false, default: null}) protected dataKey!: string;
  @Prop({type: Boolean, default: false}) protected sticky!: boolean;

  private showError: boolean = false;

  public refetch() {
    if (this.queryResult) {
      return this.queryResult.refetch();
    }
  }

  private get queryResult(): SmartQuery<any> | null {
    if (this.$refs.apolloQuery) {
      return (this.$refs.apolloQuery as any).getApolloQuery();
    }

    return null;
  }

  private resolveData(data: any) {
    if (data) {
      data = this.dataKey && this.dataKey in data ? data[this.dataKey] : data;
      this.$emit('data', data);
    }

    if (this.queryResult) {
      this.$emit('query', this.queryResult);
    }

    return this.update ? this.update(data) : data;
  }
}
