
























































import {Component, Mixins, Prop, Vue} from 'vue-property-decorator';
import ActionModal from '@/layouts/back-office/elements/actions/ActionModal.vue';
import {
  AttachDealerUserVariables,
  PaginateDealerAccounts_paginateDealerAccounts_data,
  PaginateDealerAccounts_paginateDealerAccounts_data_addresses,
  UserInput,
} from '@/types/intrador';
import InputSelect from '@/layouts/back-office/elements/input/InputSelect.vue';
import InputInspector from '@/components/elements/input/InputInspector.vue';
import ActionMixin from '@/layouts/back-office/elements/actions/ActionMixin';
import {DealerDetail} from '@/types/auction';

@Component({
  components: {ActionModal, InputSelect, InputInspector},
})
export default class AttachDealerAccountAction extends Mixins(ActionMixin) {
  public icon: string = 'plus';
  public permission?: string | string[] = 'dealers-accounts-edit';

  @Prop(Object) private dealer!: DealerDetail;
  @Prop({type: Object, required: false}) private branch?: any;

  private inspector: PaginateDealerAccounts_paginateDealerAccounts_data | string | null = null;
  private branchIds: string[] = [];

  public open() {
    const modal = this.$refs.actionModal as ActionModal;
    this.inspector = null;
    modal.open();
  }

  public close() {
    const modal = this.$refs.actionModal as ActionModal;
    modal.close();
  }

  protected onSelect() {
    this.open();
  }

  protected done() {
    this.$emit('done');
  }

  private get variables(): AttachDealerUserVariables |  null {
    if (!this.userVariable || this.branchIds.length === 0) {
      return null;
    }

    return {
      dealerId: this.dealer.id,
      branchIds: this.branchIds,
      user: this.userVariable,
    };
  }

  private get userVariable(): UserInput | null {
    if (!this.inspector) {
      return null;
    }

    if (typeof this.inspector === 'string') {
      return {email: this.inspector};
    }

    return {id: this.inspector.id};
  }

  private get canSave() {
    return this.variables !== null;
  }

  private get inspectorAddresses(): PaginateDealerAccounts_paginateDealerAccounts_data_addresses[] | null {
    if (!this.inspector || typeof this.inspector === 'string' || !this.inspector.addresses) {
      return null;
    }

    return this.inspector.addresses as PaginateDealerAccounts_paginateDealerAccounts_data_addresses[];
  }

  private get branchesOptions() {
    return this.dealer.dealerAddresses;
  }

  /**
   * Method called when inspector selected when searching for one this
   *
   * @param inspector
   * @private
   */
  private inspectorChanged(inspector: PaginateDealerAccounts_paginateDealerAccounts_data | string) {
    this.inspector = inspector;
    if (typeof this.inspector === 'string') {
      this.branchIds = [];
      return;
    }

    const addressIds = (this.inspector.addresses ?? [])
      .map((address) => address?.id)
      .filter((addressId) => addressId);

    this.branchIds = this.dealer.dealerAddresses
      .filter((address) => addressIds.indexOf(address.id) >= 0)
      .map((address) => address.id);
  }
}
