var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('query-data-table',{ref:"queryDataTable",attrs:{"data-key":"paginateInspections","row-height":63,"query":require('@/graphql/Snapshots.gql'),"variables":_vm.variables,"columns":_vm.columns,"header":false,"bordered":false,"infinite":true},scopedSlots:_vm._u([{key:"id",fn:function(ref){
var value = ref.value;
var column = ref.column;
var item = ref.item;
return [(item.thumb)?_c('img',{staticClass:"thumbnail",attrs:{"src":item.thumb,"alt":item.name}}):_c('div',{staticClass:"thumbnail"})]}},{key:"asset.name",fn:function(ref){
var value = ref.value;
var column = ref.column;
var item = ref.item;
return [_c('router-link',{staticClass:"row-title",attrs:{"to":{ name: 'inspections-detail', params: { id: item.id }}}},[_vm._v(" "+_vm._s(value)+" ")]),_c('div',{staticClass:"row-subtitle"},[_c('span',{attrs:{"aria-label":_vm.$it('global.vendor-invoice-number', 'Vendor Invoice Number'),"data-balloon-pos":"right"}},[_vm._v(" "+_vm._s(_vm._f("meta")(item.asset,'vendor_invoice_number')))]),_vm._v(" • "),_c('span',{attrs:{"aria-label":_vm.$it('global.contract-number', 'Contract Number'),"data-balloon-pos":"right"}},[_vm._v(" "+_vm._s(_vm._f("meta")(item.asset,'contract_number')))]),_vm._v(" • "),_c('span',{attrs:{"aria-label":_vm.$it('global.serial-number', 'Serial Number'),"data-balloon-pos":"left"}},[_vm._v(" "+_vm._s(item.asset.serialNumber))])])]}},{key:"address",fn:function(ref){
var value = ref.value;
var column = ref.column;
var item = ref.item;
return [(value && value.dealer)?[_c('div',{staticClass:"row-line branch"},[_c('router-link',{attrs:{"to":{ name: 'branches-detail', params: { id: value.dealer.id, branchId: value.id }}}},[_vm._v(" "+_vm._s(value.city)+" ")])],1)]:_vm._e()]}},{key:"inspector",fn:function(ref){
var value = ref.value;
var column = ref.column;
var item = ref.item;
return [(value)?[_c('div',{staticClass:"row-line"},[_vm._v(_vm._s(value.name))]),_c('div',{staticClass:"row-line"},[_vm._v(_vm._s(value.email))])]:_vm._e()]}},{key:"equipmentSituation",fn:function(ref){
var value = ref.value;
var column = ref.column;
var item = ref.item;
return [(value)?_c('equipment-situation-icon',{attrs:{"situation":value}}):_vm._e()]}},{key:"warningsAmount",fn:function(ref){
var value = ref.value;
var column = ref.column;
var item = ref.item;
return [(item.status === _vm.statusExpired)?_c('i',{staticClass:"fa fa-minus-circle"}):(value < 1)?_c('i',{staticClass:"fa fa-check-circle"}):_c('i',{staticClass:"fa fa-times-circle"})]}},{key:"plannedAt",fn:function(ref){
var value = ref.value;
var column = ref.column;
var item = ref.item;
return [(item.plannedAt && item.dueAt)?_c('div',{attrs:{"aria-label":((_vm.$id(item.plannedAt, 'time')) + " - " + (_vm.$id(item.dueAt, 'time'))),"data-balloon-pos":"left"}},[_c('div',{staticClass:"line"},[_vm._v(_vm._s(_vm.$id(item.plannedAt, 'date')))]),_c('div',{staticClass:"line"},[_vm._v(_vm._s(_vm.$id(item.dueAt, 'date')))])]):(item.plannedAt)?_c('div',{attrs:{"aria-label":("" + (_vm.$id(item.plannedAt, 'time'))),"data-balloon-pos":"left"}},[_c('div',{staticClass:"line"},[_vm._v(_vm._s(_vm.$id(item.plannedAt, 'date')))])]):_c('div',{attrs:{"aria-label":("" + (_vm.$id(item.createdAt, 'time'))),"data-balloon-pos":"left"}},[_c('div',{staticClass:"line"},[_vm._v(_vm._s(_vm.$id(item.createdAt, 'date')))])])]}},{key:"status",fn:function(ref){
var value = ref.value;
var column = ref.column;
var item = ref.item;
return [_c('inspection-status-label',{attrs:{"status":value}})]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }