















import {Component, Mixins} from 'vue-property-decorator';
import InputSelect from '@/layouts/back-office/elements/input/InputSelect.vue';
import {InputSelectOptions} from '@/layouts/back-office/elements/input/InputSelectMixin';
import InputMixin from '@/layouts/back-office/elements/input/InputMixin';
import Spinner from '@/layouts/back-office/elements/Spinner.vue';

@Component({
  components: {InputSelect, Spinner},
})
export default class InputLanguage extends Mixins(InputMixin) {

  get labelText(): string {
    if (this.label) {
      return this.label;
    }

    return this.$it('global.language', 'Language') as string;
  }

  private getOptions(data: any): InputSelectOptions {
    const options: InputSelectOptions = {};

    data.allLanguages.forEach((language: any) => {
      options[language.name] = language.languageCode;
    });

    return options;
  }
}
