import {Component, Prop, Vue} from 'vue-property-decorator';
import {StatisticsGroup} from '@/types';

export interface BarChartSeries {
  color?: string;
  label: string;
  key: string;
}

@Component
export default class BarChartMixin extends Vue {
  @Prop(Array) protected seriesOptions!: BarChartSeries[];
  @Prop(Array) protected statisticsGroups!: StatisticsGroup[];
  @Prop({type: Number, default: 260}) protected height!: number;
  @Prop({type: Boolean, default: true}) protected hasStatistics!: boolean;

  @Prop({type: String, default: () => ''}) protected context!: string;

  @Prop({
    type: String, default: 'bar', validator(value: any): boolean {
      return ['bar', 'area'].indexOf(value) >= 0;
    },
  }) protected type!: string;

  @Prop({
    type: String, default: '100%', validator(value: any): boolean {
      return ['normal', '100%'].indexOf(value) >= 0;
    },
  }) protected stackType!: string;
}
