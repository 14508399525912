








import { Component, Prop, Vue } from 'vue-property-decorator';
import { Branch_branch, Dealer_dealer, RemoveBranchVariables } from '@/types/intrador';
import ConfirmActionModal from '@/layouts/back-office/elements/actions/ConfirmActionModal.vue';

@Component({
  components: { ConfirmActionModal },
})
export default class RemoveBranchAction extends Vue {
  @Prop(Object) protected dealer!: Dealer_dealer;
  @Prop(Object) protected branch!: Branch_branch | null;

  get variables(): RemoveBranchVariables | null {
    if (!this.branch) {
      return null;
    }

    return {
      dealerId: this.dealer.id,
      branchId: this.branch.id,
    };
  }

  get modal() {
    return this.$refs.modal as ConfirmActionModal;
  }

  public open() {
    this.$nextTick(() => {
      this.modal.open();
    });
  }

  public close() {
    this.$nextTick(() => {
      this.modal.close();
    });
  }
}
