
















import {Component, Mixins, Vue} from 'vue-property-decorator';
import InputSelectMixin from '@/layouts/back-office/elements/input/InputSelectMixin';

@Component({})
export default class InputTabSelect extends Mixins(InputSelectMixin) {

}
