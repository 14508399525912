



























































































import {Component, Prop, Vue} from 'vue-property-decorator';
import {Meta} from '@sophosoft/vue-meta-decorator';
import {AuditRating} from '@/types/intrador';
import {Filter, FilterType} from '@/layouts/back-office/elements/filters/FilterMixin';
import {DataTableColumn} from '@/layouts/back-office/elements/datatable/DataTableMixin';
import QueryDataTable from '@/layouts/back-office/elements/datatable/QueryDataTable.vue';
import MetaList from '@/components/MetaList.vue';
import LastAuditRating from '@/components/planning/partials/LastAuditRating.vue';
import Score from '@/components/planning/partials/Score.vue';
import AuditStatusLabel from '@/components/audits/AuditStatusLabel.vue';
import AnomaliesAmount from '@/components/inspection/anomalies/AnomaliesAmount.vue';
import {getAuditRatingTranslation} from '@/plugins/audit/AuditRating';
import OnHoldLabel from '@/components/audits/OnHoldLabel.vue';
import {Dealer_entity, DealerAddress_address, DealerAddressDetail, DealerDetail} from '@/types/auction';

type AuditRatingString = keyof typeof AuditRating;

const starString = `<i class="fas fa-star intrador-star"></i>`;
const starStringWarning = `<i class="fas fa-star intrador-star warning"></i>`;
const starStringDanger = `<i class="fas fa-star intrador-star danger"></i>`;
const starStringDisabled = `<i class="fas fa-star intrador-star disabled"></i>`;

@Component({
  components: {
    OnHoldLabel,
    AnomaliesAmount,
    AuditStatusLabel,
    MetaList,
    QueryDataTable,
    LastAuditRating,
    Score,
  },
})
export default class AuditsTab extends Vue {
  public static starRating(rating: AuditRatingString | null) {
    if (rating === null) {
      return Vue.prototype.$it('global.na', 'N/A');
    }

    switch (AuditRating[rating]) {
      case AuditRating.CONTENT:
        return starString + starString + starString;
      case AuditRating.HALF_CONTENT:
        return starStringWarning + starStringWarning + starStringDisabled;
      case AuditRating.NOT_CONTENT:
        return starStringDanger + starStringDisabled + starStringDisabled;
      default:
        return Vue.prototype.$it('global.na', 'N/A');
    }
  }

  @Prop(Object) protected dealer!: DealerDetail;
  @Prop(Object) protected address?: DealerAddressDetail;

  get variables() {
    const variables: any = {dealerIds: [this.dealer.id]};
    if (this.address) {
      variables.branchIds = [this.address.id];
    }
    return variables;
  }

  /**
   * The DataTable colums
   */
  get columns(): DataTableColumn[] {
    return [
      {
        title: '#',
        key: 'id',
        class: 'data-table-col-fixed',
        width: 50,
      },
      {
        title: this.$it('global.audit', 'Audit') as string,
        key: 'branch.name',
        class: 'data-table-col-min',
        width: 200,
        sortable: 'address.name',
      },
      {
        title: this.$it('snapshot.col.planning.title', 'Planning') as string,
        key: 'plannedAt',
        class: 'data-table-col-fixed',
        width: 110,
        sortable: true,
      },
      {
        title: this.$it('global.assets', 'Assets') as string,
        key: 'inspections.length',
        slot: 'inspectionAmount',
        class: 'data-table-col-fixed data-table-col-center',
        width: 80,
        sortable: 'inspectionsAmount',
      },
      {
        title: this.$it('equipment-situation.sold.title', 'Sold') as string,
        key: 'sold',
        slot: 'equipmentSituation',
        class: 'data-table-col-fixed data-table-col-center',
        width: 80,
        sortable: 'soldEquipmentSituationAmount',
      },
      {
        title: this.$it('equipment-situation.rental.title', 'Rental') as string,
        key: 'rental',
        slot: 'equipmentSituation',
        class: 'data-table-col-fixed data-table-col-center',
        width: 80,
        sortable: 'rentalEquipmentSituationAmount',
      },
      {
        title: this.$it('equipment-situation.demo.title', 'Demo') as string,
        key: 'in_demonstration',
        slot: 'equipmentSituation',
        class: 'data-table-col-fixed data-table-col-center',
        width: 80,
        sortable: 'inDemonstrationEquipmentSituationAmount',
      },
      {
        title: this.$it('inspection.status.expired.title', 'Expired') as string,
        key: 'expiredInspections',
        slot: 'number',
        class: 'data-table-col-fixed data-table-col-center',
        width: 80,
        sortable: 'expiredStatusAmount',
      },
      {
        title: this.$it('audit.detail.anomalies-amount', 'Anomalies') as string,
        key: 'inspectionsWithAnomaliesAmount',
        slot: 'anomalies',
        class: 'data-table-col-fixed data-table-col-center',
        width: 110,
        sortable: 'inspectionsWithAnomaliesAmount',
      },
      {
        title: this.$it('audit.detail.score', 'Score') as string,
        key: 'score',
        class: 'data-table-col-fixed data-table-col-center',
        width: 80,
        permission: 'inspections-score-get',
        sortable: 'score',
      },
      {
        title: this.$it('audit.detail.status', 'Status') as string,
        key: 'status',
        class: 'data-table-col-fixed data-table-col-center',
        width: 120,
        sortable: true,
      },
      {
        title: this.$it('audit.detail.trusted', 'Trusted') as string,
        key: 'trusted',
        class: 'data-table-col-fixed data-table-col-center',
        width: 90,
      },
    ];
  }

  private inspectionAmount(item: any, column: any) {
    const rowItem = item.inspectionSituationCount.find((row: any) =>
      row.name.toLowerCase() === column.key.toLowerCase());

    if (rowItem) {
      return rowItem.count;
    } else {
      return 0;
    }
  }

  private refetch() {
    (this.$refs.queryDataTable as QueryDataTable).refresh();
    if (this.$refs.query) {
      (this.$refs.query as any).getApolloQuery().refetch();
    }
  }

  private mounted() {
    this.$nextTick(() => {
      this.refetch();
    });
  }

  @Meta
  private getMetaInfo() {
    return { title: this.$it('audit.list.title', 'Audits') };
  }
}
