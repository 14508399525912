var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"accounts-tab"},[_c('query-data-table',{ref:"queryDataTable",attrs:{"data-key":"paginateDealerAccounts","title":_vm.$it('accounts.list.title', 'Accounts'),"query":require('@/graphql/PaginateDealerAccounts.gql'),"row-height":64,"variables":_vm.variables,"columns":_vm.columns,"header":false,"bordered":false,"infinite":true},on:{"refetchQuery":_vm.refetchQueryUpdated},scopedSlots:_vm._u([{key:"name",fn:function(ref){
var value = ref.value;
var column = ref.column;
var item = ref.item;
return [_c('div',{staticClass:"row-title"},[_c('router-link',{attrs:{"to":{ name: 'inspections', query: { 'inspectorId': item.id }}}},[_vm._v(_vm._s(value))])],1)]}},{key:"entity.name",fn:function(ref){
var value = ref.value;
var column = ref.column;
var item = ref.item;
return [_c('router-link',{attrs:{"to":{ name: 'dealers-detail', params: { id: item.entity.id }}}},[_vm._v(_vm._s(value))])]}},{key:"email",fn:function(ref){
var value = ref.value;
var column = ref.column;
var item = ref.item;
return [_c('div',{staticClass:"row-line"},[_vm._v(_vm._s(value))])]}},{key:"addresses",fn:function(ref){
var value = ref.value;
var column = ref.column;
var item = ref.item;
return [(value.length > 0)?[_c('div',{staticClass:"cities"},[_vm._l((_vm.getAddressesOfCurrentBranch(value)),function(address){return [_c('div',{staticClass:"row-address"},[_c('router-link',{attrs:{"to":{ name: 'branches-detail', params: { id:address.entity.id, branchId: address.id }}}},[_vm._v(" "+_vm._s(address.city)+" ")])],1)]})],2)]:[_c('div',{staticClass:"cities"},[_vm._v(" "+_vm._s(_vm.$it('global.na', 'N/A'))+" ")])]]}},{key:"language",fn:function(ref){
var value = ref.value;
var column = ref.column;
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("language")(value))+" ")]}},{key:"hasPassword",fn:function(ref){
var value = ref.value;
var column = ref.column;
var item = ref.item;
return [(value)?_c('i',{staticClass:"fa fa-check-circle"}):_c('i',{staticClass:"fa fa-times-circle"})]}},{key:"inspectionsAmountTotal",fn:function(ref){
var value = ref.value;
var column = ref.column;
var item = ref.item;
return [_vm._v(" "+_vm._s(item.inspectionsAmountOpen)+" / "+_vm._s(item.inspectionsAmountTotal)+" ("+_vm._s(_vm.$n(item.inspectionsAmountOpen / item.inspectionsAmountTotal * 100, 'percentage'))+") ")]}},{key:"appVersion",fn:function(ref){
var value = ref.value;
var column = ref.column;
var item = ref.item;
return [_c('div',{staticClass:"app-version",attrs:{"aria-label":_vm.getAppVersionMessage(item),"data-balloon-pos":"left"}},[(item.device !== null)?[(item.device.systemOs === 'Android')?_c('i',{staticClass:"fab fa-android"}):(item.device.systemOs === 'iOS')?_c('i',{staticClass:"fab fa-apple"}):_vm._e()]:_vm._e(),(item.device === null)?_c('i',{staticClass:"fa fa-times-circle"}):(item.device.outdated)?_c('i',{staticClass:"fas fa-clock"}):_c('i',{staticClass:"fa fa-check-circle"}),(value)?_c('label',[_vm._v(_vm._s(value))]):_vm._e()],2)]}},{key:"edit",fn:function(ref){
var value = ref.value;
var column = ref.column;
var item = ref.item;
return [_c('div',[(_vm.$can('dealers-accounts-edit'))?_c('button',{staticClass:"account-edit",attrs:{"aria-label":_vm.$it('global.edit', 'Edit'),"data-balloon-pos":"left"},on:{"click":function($event){return _vm.editAccount(item)}}},[_c('i',{staticClass:"fa fa-pencil-alt"})]):_vm._e(),(_vm.$can('dealers-accounts-edit'))?_c('button',{staticClass:"account-edit",attrs:{"aria-label":_vm.$it('dealer.user.detach.title', 'Detach account'),"data-balloon-pos":"left"},on:{"click":function($event){return _vm.detachAccount(item)}}},[_c('i',{staticClass:"fa fa-times"})]):_vm._e()])]}}])}),(_vm.editableItem)?_c('div',[(_vm.$can('dealers-accounts-edit'))?_c('edit-account-action',{ref:"editAccountAction",attrs:{"account":_vm.editableItem}}):_vm._e(),(_vm.$can('dealers-accounts-edit'))?_c('detach-dealer-account-action',{ref:"detachDealerAccountAction",attrs:{"paginate-dealer-accounts-query":_vm.refetchQuery,"account":_vm.editableItem,"dealer-id":_vm.dealer.id,"branch":_vm.branch}}):_vm._e()],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }