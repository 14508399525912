








































import {Component, Prop, Vue, Watch} from 'vue-property-decorator';
import {BranchInput, CreateBranchVariables, Dealer_dealer} from '@/types/intrador';
import Modal from '@/layouts/back-office/elements/modals/Modal.vue';
import SpinnerButton from '@/layouts/back-office/elements/SpinnerButton.vue';
import InputText from '@/layouts/back-office/elements/input/InputText.vue';
import InputInspector from '@/components/elements/input/InputInspector.vue';
import InputDate from '@/layouts/back-office/elements/input/InputDate.vue';
import InputAddress, {Address} from '@/layouts/back-office/elements/input/InputAddress.vue';

@Component({
  components: {
    InputAddress,
    InputInspector,
    InputText,
    InputDate,
    SpinnerButton,
    Modal,
  },
})
export default class CreateBranchAction extends Vue {
  @Prop(Array) protected refetchQueries?: any[];
  @Prop(Object) protected dealer!: Dealer_dealer;

  private loading = false;
  private error: boolean | string = false;

  private address: Address = {
    name: null,
    externalId: null,
    addressLine1: null,
    city: null,
    zipCode: null,
    state: null,
    country: null,
    remark: null,

    contactName: null,
    email: null,
    phone: null,

    longitude: null,
    latitude: null,

    auditFrequency: null,
    nextAudit: null,

    accountManager: null,
  };

  private savable: boolean = false;

  get addressInput(): BranchInput {
    return {
      ...this.$inspection.resolveAddressInput(this.address),
    };
  }

  get inputAddressRef(): InputAddress {
    return this.$refs.inputAddress as InputAddress;
  }

  public open() {
    this.$router.replace({
      name: this.$route.name || undefined,
      query: {},
      params: this.$route.params,
    });

    this.address.name = this.dealer.name;
    this.address.externalId = this.dealer.externalId;

    this.modal.open();

    // Automatically focus the input
    this.$nextTick(() => {
      if (this.$refs.nameInput) {
        (this.$refs.nameInput as InputText).becomeFirstResponder();
      }
    });
  }

  public close() {
    this.modal.close();
  }

  @Watch('address')
  private check() {
    this.savable = this.inputAddressRef.silentCheck();
  }

  private get variables(): CreateBranchVariables {
    return {
      dealerId: this.dealer.id,
      externalId: this.address.externalId!,
      branch: this.addressInput,
    };
  }

  private get modal() {
    return this.$refs.createBranchModal as Modal;
  }

  private async save() {
    if (!this.inputAddressRef.check()) {
      return;
    }

    this.loading = true;
    this.error = false;

    try {
      const { data } = await this.$apollo.mutate({
        mutation: require('@/graphql/mutations/CreateBranch.gql'),
        variables: this.variables,
      });

      this.$emit('done', {address: this.address});
      this.close();
    } catch (e) {
      this.error = e.message;
    }

    this.loading = false;
  }
}
