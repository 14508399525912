


















import {Component, Prop, Mixins} from 'vue-property-decorator';
import Card from '@/layouts/back-office/elements/Card.vue';
import MonthBarChart from '@/layouts/back-office/elements/charts/MonthBarChart.vue';
import {Dealer, InspectionEquipmentSituation} from '@/types/auction';
import MonthChartMixin from '@/layouts/back-office/elements/charts/MonthChartMixin';
import {StatisticsGroup, StatisticsPartition} from '@/types';

@Component({
  components: {MonthBarChart, Card},
})
export default class MonthlyEquipmentSituation extends Mixins(MonthChartMixin) {
  @Prop(Object) protected data!: Dealer;
  @Prop({type: Number, default: 450}) protected height!: number;

  protected situations = [
    InspectionEquipmentSituation.FOUND,
    InspectionEquipmentSituation.SOLD,
    InspectionEquipmentSituation.IN_DEMONSTRATION,
    InspectionEquipmentSituation.RENTAL,
    InspectionEquipmentSituation.OTHER_SITUATION,
  ];

  get series() {
    return this.$snapshot.equipmentSituation.getSeries(this.situations);
  }

  get statistics(): StatisticsGroup[] {
    const statistics = this.data.paginateInspections?.statistics.equipmentSituationByMonth ?? [];

    return statistics.map((group): StatisticsGroup => {
      return {
        id: group.id,
        name: group.name,
        partitions: group.partitions.reduce((total: StatisticsPartition[], item): StatisticsPartition[] => {
          if (item.name && this.situations.indexOf(item.name) >= 0
            && item.name !== InspectionEquipmentSituation.OTHER_SITUATION) {

            total.push({
              id: item.id,
              name: item.name,
              amount: item.amount,
            });
            return total;
          }

          // All other situation place in other
          const partition =
            total.find((p) => p.name === InspectionEquipmentSituation.OTHER_SITUATION);

          if (partition) {
            partition.amount! += item.amount;
            return total;
          }

          total.push({
            id: group.name + '-' + InspectionEquipmentSituation.OTHER_SITUATION,
            name: InspectionEquipmentSituation.OTHER_SITUATION,
            amount: item.amount,
          });
          return total;
        }, []),
      };
    });
  }

  get hasStatistics() {
    return this.statistics.length > 0;
  }
}
