

















import {Component, Mixins, Prop} from 'vue-property-decorator';
import {StatisticsGroup} from '@/types';
import MonthBarChart from '@/layouts/back-office/elements/charts/MonthBarChart.vue';
import Card from '@/layouts/back-office/elements/Card.vue';
import {Dealer, InspectionEquipmentSituation} from '@/types/auction';
import MonthChartMixin from '@/layouts/back-office/elements/charts/MonthChartMixin';
import {InspectionStatus} from '@/types/intrador';

@Component({
  components: {Card, MonthBarChart},
})
export default class MonthlyStatus extends Mixins(MonthChartMixin) {
  @Prop(Object) protected data!: Dealer;
  @Prop({type: Number, default: 450}) protected height!: StatisticsGroup[];

  protected status = [
    InspectionStatus.PLANNED,
    InspectionStatus.STARTED,
    InspectionStatus.FINISHED,
    InspectionStatus.ON_HOLD,
    InspectionStatus.SUBMITTED,
    InspectionStatus.ACCEPTED,
    InspectionStatus.DECLINED,

    InspectionStatus.EXPIRED,
  ];

  get series() {
    return this.$inspection.status.getSeries(this.status);
  }

  get statistics() {
    return this.data.paginateInspections?.statistics.statusByMonth ?? [];
  }

  get hasStatistics() {
    return this.statistics.length > 0;
  }
}
