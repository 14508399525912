





























import {Component, Prop, Vue, Watch} from 'vue-property-decorator';
import Input from '@/components/elements/input/Input.vue';
import InputText from '@/layouts/back-office/elements/input/InputText.vue';
import LanguageSelect from '@/layouts/back-office/elements/input/InputLanguage.vue';
import Modal from '@/layouts/back-office/elements/modals/Modal.vue';
import SpinnerButton from '@/layouts/back-office/elements/SpinnerButton.vue';
import UserForm, {UserFormResponse} from '@/components/account/forms/UserForm.vue';
import {UpdateUserInput, User} from '@/types/auction';

@Component({
  components: {
    UserForm,
    Input,
    InputText,
    LanguageSelect,
    Modal,
    SpinnerButton,
  },
})
export default class EditAccountAction extends Vue {
  @Prop(Object) protected account!: User;

  protected id: string|null = null;
  protected user: UserFormResponse|null = null;

  public open() {
    const modal = this.$refs.editAccountModal as Modal;

    modal.open();
  }

  public close() {
    const modal = this.$refs.editAccountModal as Modal;

    modal.close();

    this.user = null;
  }

  protected get variables(): UpdateUserInput | null {
    if (this.id === null || this.user === null) {
      return null;
    }

    return {
      id: this.id,

      email: this.user.email,

      displayName: this.user.displayName,

      firstName: this.user.firstName,
      lastName: this.user.lastName,

      phone: this.user.phone,

      language: this.user.language,
      roles: this.user.activeRoles,
    };
  }

  @Watch('account', {immediate: true})
  private accountUpdated(user: User | null) {
    if (user) {
      this.id = user.id;

      this.user = {
        email: user.email,
        displayName: user.displayName,
        firstName: user.firstName ?? '',
        lastName: user.lastName ?? '',
        phone: user.phone,
        language: user.language,
        activeRoles: user.roles?.map((role) => role.id) ?? [],
      };
    }
  }
}
