var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"branches-tab"},[_c('query-data-table',{ref:"queryDataTable",attrs:{"data-key":"paginateBranches","query":require('@/graphql/queries/branches/PaginateBranches.gql'),"variables":_vm.variables,"row-height":63,"columns":_vm.columns,"header":false,"bordered":false,"infinite":true},scopedSlots:_vm._u([{key:"externalId",fn:function(ref){
var value = ref.value;
var column = ref.column;
var item = ref.item;
return [_c('div',{staticClass:"line",attrs:{"title":value}},[_vm._v(_vm._s(value))])]}},{key:"name",fn:function(ref){
var value = ref.value;
var column = ref.column;
var item = ref.item;
return [_c('router-link',{staticClass:"go-to-branch",attrs:{"to":{name: 'branches-detail', params: {id: _vm.dealer.id, branchId: item.id}}}},[_vm._v(" "+_vm._s(value)+" ")]),_vm._v(" "+_vm._s(item.line)+" ")]}},{key:"auditFrequency",fn:function(ref){
var value = ref.value;
var column = ref.column;
var item = ref.item;
return [_vm._v(" "+_vm._s(value)+" "+_vm._s(_vm.$it('global.days', 'days'))+" ")]}},{key:"date",fn:function(ref){
var value = ref.value;
var column = ref.column;
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.$id(value, 'date'))+" ")]}},{key:"nextAudit",fn:function(ref){
var value = ref.value;
var column = ref.column;
var item = ref.item;
return [(value)?[_c('div',[_vm._v(_vm._s(_vm.$id(value, 'date')))]),_vm._v(" "+_vm._s(_vm.$it('global.every', 'Every'))+" "+_vm._s(item.auditFrequency)+" "+_vm._s(_vm.$it('global.days', 'days'))+" ")]:_vm._e()]}},{key:"nextTrustedAudit",fn:function(ref){
var value = ref.value;
var column = ref.column;
var item = ref.item;
return [(value)?[_c('div',[_vm._v(_vm._s(_vm.$id(value, 'date')))]),_vm._v(" "+_vm._s(_vm.$it('global.every', 'Every'))+" "+_vm._s(item.trustedAuditFrequency)+" "+_vm._s(_vm.$it('global.days', 'days'))+" ")]:_vm._e()]}},{key:"contact",fn:function(ref){
var value = ref.value;
var column = ref.column;
var item = ref.item;
return [_c('div',{staticClass:"contact-information"},[(item.contactName)?_c('span',{staticClass:"contact-name"},[_vm._v(_vm._s(item.contactName))]):_vm._e(),(item.email || item.phone)?_c('div',{staticClass:"row"},[(item.email)?_c('div',{staticClass:"col-auto"},[_c('a',{staticClass:"contact-email",attrs:{"href":'mailto:' + item.email}},[_vm._v(_vm._s(item.email))])]):_vm._e(),(item.phone)?_c('div',{staticClass:"col-auto"},[_c('a',{staticClass:"contact-phone",attrs:{"href":'tel:' + item.phone}},[_vm._v(_vm._s(item.phone))])]):_vm._e()]):_vm._e()])]}},{key:"edit",fn:function(ref){
var value = ref.value;
var column = ref.column;
var item = ref.item;
return [_c('div',[(_vm.$can('dealers-branches-edit'))?_c('button',{staticClass:"branch-edit",attrs:{"aria-label":_vm.$it('global.edit', 'Edit'),"data-balloon-pos":"left"},on:{"click":function($event){return _vm.branchEdit(item)}}},[_c('i',{staticClass:"fa fa-pencil-alt"})]):_vm._e(),(_vm.$can('dealers-branches-remove'))?_c('button',{staticClass:"branch-remove",attrs:{"aria-label":_vm.$it('global.remove', 'Remove'),"data-balloon-pos":"left"},on:{"click":function($event){return _vm.branchRemove(item)}}},[_c('i',{staticClass:"fa fa-times"})]):_vm._e()])]}}])}),(_vm.$can('dealers-branches-edit'))?_c('edit-branch-action',{ref:"editBranchModal",attrs:{"branch":_vm.editableItem}}):_vm._e(),(_vm.$can('dealers-branches-remove'))?_c('remove-branch-action',{ref:"removeBranchModal",attrs:{"branch":_vm.editableItem,"dealer":_vm.dealer},on:{"done":_vm.refetch}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }